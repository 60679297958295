
import { objectDifference } from "@/helpers";
import { cloneDeep, get } from "lodash";
import {
  propertyChangeRequestMapMutations,
  propertyChangeRequestMapActions,
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import * as types from "@/store/mutation-types";
import { IAddress } from "@/types";
import { omit } from "lodash";
import insuredSchemas from "@/forms/shared/changeRequestInsuredSchema";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
interface IAgencyAddress {
  agencyCity: string;
  agencyZipCode: string;
  agencyState: string;
  successMessage: string;
  errorMessage: string;
  isEdit: boolean;
  uploadedFile: any[];
}

export default VueWithMixins(ChangeRequestMixin).extend({
  mixins: [ActivitiesMixin],
  components: {
    PolicyInfo: () => import("@/components/User/PolicyInfo.vue"),
    PropertyChangeRequestActions: () =>
      import("@/components/User/ChangeRequestActions.vue"),
    ChangeRequestInsured: () =>
      import("@/components/User/ChangeRequestInsured.vue"),
    CustomAlert
  },
  props: {
    propertyChangeRequestData: {
      type: Object
    },
    propertyChangeRequestId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      successMessage: "",
      isEdit: true,
      uploadedFile: [],
      isPolicyInfoValid: false,
      isInsuredFieldsValid: false,
      insuredSchemas,
      showActivities: false,
      selectedTabKey: "edit",
      disableSubmitOnMortgageCheck: false,
      hasValidDescription: false
    };
  },
  mounted() {
    this.editingField = this.editField;
  },
  destroyed() {
    this.setState({ key: "editing", value: null });
  },
  methods: {
    attachmentData(data: any) {
      this.uploadedFile = data;
      this.updateFile();
    },
    ...propertyChangeRequestMapActions([
      "deletePropertyChangeRequest",
      "updatePropertyChangeRequest"
    ]),
    ...propertyChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD,
      setState: types.SET_STATE
    }),
    async deleteCallback() {
      try {
        this.$modal.show("propertyRequestSingleDeleteModal");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOnePropertyChangeRequest(): Promise<void> {
      await this.deletePropertyChangeRequest(this.propertyChangeRequestId);
      this.$modal.hide("propertyRequestSingleDeleteModal");
      this.goBackToListView("/propertychangerequests");
      this.$appNotifySuccess("Policy Change Request Deleted.");
    },
    handleDisableSubmitButtonOnMortgageCheck($event: any) {
      this.disableSubmitOnMortgageCheck = $event;
    },
    async sendData() {
      const action: Record<string, any> = get(this.editing?.data, "action", {});
      if (
        action &&
        Object.keys(action).length &&
        !action.limitAction &&
        !action.endorsementAction &&
        !action.mortgageAction &&
        !this.disableSubmitOnMortgageCheck
      ) {
        this.errorMessage =
          "You must select and provide details for at least one section to be able to submit this request";
        this.$emit("scrollTop");
        return;
      }

      let updateData: any = cloneDeep(this.editing);
      delete updateData.data?.agentInfo;
      await this.updatePropertyChangeRequest({
        id: this.propertyChangeRequestId,
        update: this.updatedFields
      });
    },
    async updateFile(): Promise<void> {
      const status = this.editing?.status;
      if (status !== "Not Submitted") {
        return;
      }

      try {
        this.loading = true;
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        this.mapFileData(response, this.editing);
        await this.sendData();
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    async update(): Promise<void> {
      const status = this.editing?.status;
      if (status !== "Not Submitted") {
        return;
      }

      try {
        if (Object.keys(this.updatedFields).length === 0) {
          return;
        }
        return await this.sendData();
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.$emit("scrollTop");
      }
    },
    async Submit() {
      try {
        await this.updatePropertyChangeRequest({
          id: this.propertyChangeRequestId,
          update: {
            status: "Submitted",
            submittedOn: new Date(),
            ...this.updatedFields
          }
        });
        this.goBackToListView("/propertychangerequests");
        this.$appNotifySuccess("Policy Change Request submitted");
      } catch (e) {
        this.$appNotifyError("Error updating Policy Change Request");
        this.$bugSnagClient.notify(e);
      }
    },
    policyInfoFieldValid(data: any) {
      this.isPolicyInfoValid = data;
    },
    insuredFieldsValid(data: any) {
      this.isInsuredFieldsValid = data;
    },
    changeAddressProperties(agencyAddress: IAgencyAddress): IAddress {
      let newAgencyAddressProperties: IAddress = {
        unitNumber: undefined,
        streetName: "",
        houseNumber: 0,
        streetDirection: "",
        streetType: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        line2: "",
        county: ""
      };
      newAgencyAddressProperties.city = agencyAddress.agencyCity;
      newAgencyAddressProperties.zipCode = agencyAddress.agencyZipCode;
      newAgencyAddressProperties.state = agencyAddress.agencyState;

      return newAgencyAddressProperties;
    },
    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "delete":
          this.deleteCallback();
          break;
        case "print":
          this.printCallback(
            this.editing,
            "propertychangerequest/printPropertyChangeRequest"
          );
          break;
        case "submit":
          if (!this.isPolicyInfoValid) {
            return;
          }
          this.Submit();
          break;
        case "cancel":
          this.goBackToListView("/propertychangerequests");
          break;
      }
    }
  },
  computed: {
    ...propertyChangeRequestMapState(["makingApiRequest", "editing"]),
    ...propertyChangeRequestMapGetters(["getPropertyChangeRequestById"]),
    policyInfo(): Record<string, any> {
      return this.propertyChangeRequestData?.data?.policyInfo;
    },
    original(): any {
      return this.getPropertyChangeRequestById(this.propertyChangeRequestId);
    },
    updatedFields(): any {
      const toIgnore = ["data.agentInfo"];
      const allowEmpty = [
        "data.actions.limitAction",
        "data.description.limitDescription",
        "data.actions.endorsementAction",
        "data.description.endorsementDescription",
        "data.mortgage.mortgage",
        "data.actions.mortgageAction",
        "data.mortgage.mortgageAddress.state",
        "data.mortgage.mortgageAddress.city",
        "data.mortgage.mortgageAddress.zipCode",
        "data.mortgage.address",
        "data.mortgage.payor",
        "data.mortgage.mortgagePosition",
        "data.mortgage.payPlanType"
      ];
      return omit(
        this.editing && this.original
          ? objectDifference(this.editing, this.original, allowEmpty)
          : {},
        toIgnore
      );
    },
    checkValidMortgageAction(): boolean {
      const { actions } = this.editing?.data || {};
      if (actions?.mortgageAction) {
        if (this.actionIsDeleteMortgage(this.editing)) {
          return this.hasValidMortgageBillInsured(this.editing);
        }
      }
      return true;
    },

    disableSubmitButton(): boolean {
      return (
        !this.isPolicyInfoValid ||
        !this.checkValidMortgageAction ||
        this.disableSubmitOnMortgageCheck ||
        !this.hasSelectedMortgageAction(this.editing) ||
        !this.hasValidMortgageBillInsured(this.editing) ||
        (!this.actionIsDeleteMortgage(this.editing) &&
          this.policyIsLapsedAndHasNoAttachments(this.editing))
      );
    },
    primaryToolbar(): any {
      if (this.editing && this.editing.status === "Not Submitted") {
        return {
          text: "Submit",
          key: "submit",
          loading: this.makingApiRequest,
          disabled: this.disableSubmitButton
        };
      }
      return undefined;
    },
    secondaryToolbar(): any {
      if (this.editing) {
        const defaultActions = {
          text: "Action",
          key: "actions",
          subItems: [
            {
              title: "Print",
              command: "print",
              loading: this.makingApiRequest
            },
            {
              title: "Delete",
              command: "delete"
            }
          ]
        };
        if (this.editing.status === "Submitted") {
          return defaultActions;
        } else {
          defaultActions.subItems.push({
            title: "Cancel",
            command: "cancel",
            loading: this.makingApiRequest
          });
          return defaultActions;
        }
      }
      return undefined;
    },
    queryOverride(): any {
      return {
        "data.policyChangeRequestId": this.editing?._id,
        activityType: "PolicyChangeRequestActivity"
      };
    }
  },
  watch: {
    updatedFields: {
      handler(fields) {
        if (Object.keys(fields).length && !fields.attachments) {
          this.$nextTick().then(() => {
            this.update();
          });
        }
      },
      deep: true
    }
  }
});
